import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { ValidationRule } from '../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { IamStore } from '../../../services/iam.store';
import { PrivilegedAccessGroupAssignmentRequestDto, PrivilegedAccessGroupAssignmentResponseDto, PrivilegedAccessGroupCheckAssignmentsResponseDto, PrivilegedAccessGroupCheckOwnershipResponseDto } from './privileged-group.model';


export const IamPrivilegedAccessGroupApiUrl = {
  PrivilegedGroupCheckOwnership: () => "/api/v1/iam/privileged-group/check-ownership",
  PrivilegedGroupCheckAssignments: () => "/api/v1/iam/privileged-group/check-assignments",
  PrivilegedGroupAssign: () => "/api/v1/iam/privileged-group/assign"
}

@Injectable({providedIn: 'root'})
export class PrivilegedGroupService extends BaseApiService{

  constructor(store: IamStore) {
    super(store.apiUrl);
  }

  //getValidators(method: keyof typeof GpsNetworkRequestApiUrl) {
  //  return super.get<ValidationRule[]>(GpsConfigurationApiUrl.ConfigurationValidators(), { method });
  //}

  //#region Network Requests

  privilegedGroupCheckOwnership(): Observable<PrivilegedAccessGroupCheckOwnershipResponseDto> {
    return super.get<PrivilegedAccessGroupCheckOwnershipResponseDto>(IamPrivilegedAccessGroupApiUrl.PrivilegedGroupCheckOwnership());
  }

  privilegedGroupCheckAssignments(roleName: string = ''): Observable<PrivilegedAccessGroupCheckAssignmentsResponseDto> {
    return super.post<PrivilegedAccessGroupAssignmentRequestDto, PrivilegedAccessGroupCheckAssignmentsResponseDto>(
      IamPrivilegedAccessGroupApiUrl.PrivilegedGroupCheckAssignments(), new PrivilegedAccessGroupAssignmentRequestDto({ roleName }));
  }

  privilegedGroupAssign(roleName: string = '', justification: string = ''): Observable<PrivilegedAccessGroupAssignmentResponseDto> {
    return super.post<PrivilegedAccessGroupAssignmentRequestDto, PrivilegedAccessGroupAssignmentResponseDto>(
      IamPrivilegedAccessGroupApiUrl.PrivilegedGroupAssign(), new PrivilegedAccessGroupAssignmentRequestDto(new PrivilegedAccessGroupAssignmentRequestDto({ roleName, justification })));
  }

  //#endregion
}
