import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelModule } from 'primeng/panel';

import { ControlErrorModule } from '../../../shared/directives/control-error.module';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PrivilegedGroupRoutingModule } from './privileged-group-routing.module';
import { PrivilegedRoleAssignComponent } from './components/role-assign.component';


@NgModule({
  declarations: [
    PrivilegedRoleAssignComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CardModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    RadioButtonModule,
    CheckboxModule,
    ButtonModule,
    DropdownModule,
    PanelModule,
    TableModule,
    ToastModule,
    InputTextModule,
    TabViewModule,
    DynamicDialogModule,
    TabViewModule,
    ControlErrorModule,
    BlockUIModule,
    ProgressSpinnerModule,

    PrivilegedGroupRoutingModule
  ]
})
export class PrivilegedGroupModule {
}
