// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IamEnvironmentConfig } from "./environment-config";

export const iamEnvironment: IamEnvironmentConfig = {
  production: false,
  api: {
    'CARIAD': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGPROD': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGST': undefined,
    'PowerCo': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGITC': {
      url: 'https://azfun-azuf-euw-d-gps-001.azurewebsites.net',
      clientId: '6eee94ed-3821-4243-8d4f-075cc993b5d8',
      authority: 'https://login.microsoftonline.com/9f69edf3-ef71-467c-8885-5e380cdc20cd',
      scopes: ['api://6eee94ed-3821-4243-8d4f-075cc993b5d8/all']
    }
  }
};
