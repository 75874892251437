
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { NotificationService } from '../../../../shared/services/notification.service';
import { mapToLabelValueArray } from '../../../../shared/utils';
import { PrivilegedAccessGroupAssignmentResponseDto, PrivilegedAccessGroupAssignmentStatus, PrivilegedAccessGroupCheckAssignmentsResponseDto, PrivilegedAccessGroupCheckOwnershipResponseDto, PrivilegedGroupOptions } from '../api/privileged-group.model';
import { PrivilegedGroupService } from '../api/privileged-group.service';

@Component({
  templateUrl: './role-assign.component.html'
})
export class PrivilegedRoleAssignComponent implements OnInit {

  form: FormGroup = this.fb.group({ });
  privilegedGroupOptions = mapToLabelValueArray(PrivilegedGroupOptions);
  showCheckAssignments = false;
  existingAssignment: PrivilegedAccessGroupAssignmentResponseDto | null = null;
  formBlocked = false;

  constructor(
    private fb: FormBuilder,
    private privilegedGroupService: PrivilegedGroupService,
    private notify: NotificationService,
    //private iamStore: IamStore,
    private router: Router,
  ) {
  }

  async ngOnInit() {

    this.form = this.fb.group({
      roleName: [null, [Validators.required]],
      justification: [null, [Validators.required]],
      checkOwnership: [null, [Validators.requiredTrue]],
      checkAssignments: [null, [Validators.requiredTrue]],
    });

    this.privilegedGroupService.privilegedGroupCheckOwnership()
      .subscribe((r: PrivilegedAccessGroupCheckOwnershipResponseDto) => {
        this.form.patchValue({ checkOwnership: r.isOwner });
      });

    //this.addValidators();
  }

  roleChanged(event: DropdownChangeEvent) {
    this.showCheckAssignments = true;
    this.form.patchValue({ checkAssignments: null });
    this.privilegedGroupService.privilegedGroupCheckAssignments(event.value)
      .subscribe((r: PrivilegedAccessGroupCheckAssignmentsResponseDto) => {
        if (r.assigned.length > 0) {
          this.existingAssignment = r.assigned[0];
          this.existingAssignment.startDateTime = new Date(this.existingAssignment.startDateTime+'Z');
          this.existingAssignment.endDateTime = new Date(this.existingAssignment.endDateTime+'Z');
        }
        this.form.patchValue({ checkAssignments: r.assigned.length == 0 });  
      });
  }

  save() {
    if (this.form.invalid) {
      this.notify.error('Invalid form');
      return;
    }
    this.formBlocked = true;
    const datePipe = new DatePipe('en-US');
    this.privilegedGroupService.
      privilegedGroupAssign(this.form.value.roleName, this.form.value.justification)
      .subscribe(r => {
        if (r.status != PrivilegedAccessGroupAssignmentStatus.Exception) {
          this.notify.success('The role has been successfully assigned', `Expiration: <b>${datePipe.transform(r.endDateTime, 'short')}</b>`, 10000);
          this.router.navigateByUrl('/iam');
        } else {
          this.notify.error('Failed', r.error);
        }
        this.formBlocked = false;
      })
  }
}
