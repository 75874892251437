//import { v4 as uuidv4 } from 'uuid';

export const PrivilegedGroupOptions = new Map<string, string>([
  ["Application Developer", 'Application Developer'],
]);


export class PrivilegedAccessGroupAssignmentRequestDto {
  roleName: string = '';
  justification: string = '';

  constructor(partial?: Partial<PrivilegedAccessGroupAssignmentRequestDto>) {
    Object.assign(this, partial);
  }
}


export enum PrivilegedAccessGroupAssignmentStatus {
  Canceled = 'Canceled',
  Denied = 'Denied',
  Failed = 'Failed',
  Granted = 'Granted',
  PendingAdminDecision = 'PendingAdminDecision',
  PendingApproval = 'PendingApproval',
  PendingProvisioning = 'PendingProvisioning',
  PendingScheduleCreation = 'PendingScheduleCreation',
  Provisioned = 'Provisioned',
  Revoked = 'Revoked',
  ScheduleCreated = 'ScheduleCreated',
  Exception = 'Exception'
}

export class PrivilegedAccessGroupAssignmentResponseDto {
  id: string = '';
  roleName: string = '';
  groupId: string = '';
  startDateTime: Date = new Date();
  endDateTime: Date = new Date();
  status: PrivilegedAccessGroupAssignmentStatus | null = null;
  error: string = '';
  constructor(partial?: Partial<PrivilegedAccessGroupAssignmentResponseDto>) {
    Object.assign(this, partial);
  }
}


export class PrivilegedAccessGroupCheckOwnershipResponseDto {
  isOwner: boolean = false;
  resourceGroups: string[] = [];
  subscriptions: string[] = [];

  constructor(partial?: Partial<PrivilegedAccessGroupCheckOwnershipResponseDto>) {
    Object.assign(this, partial);
  }
}

export class PrivilegedAccessGroupCheckAssignmentsResponseDto {
  assigned: PrivilegedAccessGroupAssignmentResponseDto[] = [];

  constructor(partial?: Partial<PrivilegedAccessGroupCheckAssignmentsResponseDto>) {
    Object.assign(this, partial);
  }
}
