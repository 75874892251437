import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IamLayoutLiteComponent } from '../layout/components/layout-lite.component';
import { PrivilegedRoleAssignComponent } from './components/role-assign.component';

const routes: Routes = [
  {
    path: 'assign',
    pathMatch: 'full',/*canActivate: [MsalGuard],*/
    component: PrivilegedRoleAssignComponent
  },
  //{
  //  path: 'list', component: NpsLayoutComponent,
  //  children: [
  //    { path: '', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: NetworkRequestListComponent },
  //    { path: ':requestId', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: NetworkRequestEditComponent },
  //  ]
  //},
  {
    path: '',
    redirectTo: 'assign',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivilegedGroupRoutingModule { }
