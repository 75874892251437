
<div class="surface-ground flex justify-content-center align-items-center mt-8">
  <div class="flex flex-column align-items-center justify-content-center">
    <div class="text-center">
      <p-panel #container header="Assign Privileged EntraId (AAD) Role" styleClass="inline-block min-w-400 max-w-1000 w-full text-left">

        <form [formGroup]="form" class="p-3">
          <div>
            <div class="field">
              <label for="roleName" class="block text-900 font-medium mb-2">Privileged Role *</label>
              <p-dropdown id="roleName" formControlName="roleName" [options]="privilegedGroupOptions" placeholder="Select a Role"
                          appendTo="body" styleClass="w-full" inputStyleClass="w-full" (onChange)="roleChanged($event)" />
            </div>
            <div class="field">
              <label for="justification" class="block text-900 font-medium mb-2">Justification *</label>
              <input id="justification" formControlName="justification" type="text" pInputText class="w-full mb-3"/>
            </div>

            <p>
              @switch(form.get('checkOwnership')?.value)
              {
              @case (null)
              {
              <i class="pi pi-spin-always pi-spinner"></i> Checking ownership ...
              }
              @case (true)
              {
              <i class="pi pi-check-circle text-green-500"></i> You have required ownership.
              }
              @case (false)
              {
              <i class="pi pi-times-circle text-red-500"></i> You don't have required ownership.
              }
              }
            </p>

            @if(showCheckAssignments){
            <p>
              @switch(form.get('checkAssignments')?.value)
              {
              @case (null)
              {
              <i class="pi pi-spin-always pi-spinner"></i> Checking existing assignments ...
              }
              @case (true)
              {
              <i class="pi pi-check-circle text-green-500"></i> You don't have existing assignments.
              }
              @case (false)
              {
              <i class="pi pi-times-circle text-red-500"></i> You already have existing assignment.<br />
              @if(existingAssignment){
              <b>{{existingAssignment.startDateTime| date:'short'}} - {{existingAssignment.endDateTime| date:'short'}}</b><br />
              <i>You can assign this role again after it expires</i>
              }
              }
              }
            </p>
            }

            <div class="text-center mt-5">
              <p-button label="Cancel" icon="pi pi-times" class="m-3" routerLink="/iam"></p-button>
              <p-button label="Assign" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
            </div>

          </div>
        </form>

      </p-panel>
    </div>
  </div>
</div>

<p-blockUI [target]="container" [blocked]="formBlocked">
  <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
</p-blockUI>
