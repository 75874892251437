import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TenantKey } from '../../shared/models/tenant';
import { iamEnvironment } from '../environments/environment';

const iamStoreKey = 'iam.store';
interface IamProps {
    user?: AccountInfo,
    //gpsUser?: UserMeDto ;
    tenant?: TenantKey;
    //permissions?: { [key: string]: RequestPermissionsType };
}

const iamStore = createStore(
    { name: 'user' },
    withProps<IamProps>({})
);

//save in local storage
export const persist = persistState(iamStore, {
    key: iamStoreKey,
    storage: localStorageStrategy,
});

iamStore.subscribe((state) => console.log(state));
//persist.initialized$.subscribe(console.log);

@Injectable({ providedIn: 'root' })
export class IamStore {

    store$ = iamStore;

    user$ = iamStore.pipe(select((state) => state.user));
    tenant$ = iamStore.pipe(select((state) => state.tenant));
    //gpsUser$ = gpsStore.pipe(select((state) => state.npsUser));

    constructor() {
    }

    get current(): IamProps {
        return iamStore.getValue();
    }

    get apiUrl(): string {
        return this.current.tenant ? iamEnvironment.api[this.current.tenant]!.url : '';
    }

    setUser(user: AccountInfo | undefined) {
        iamStore.update((state) => ({
            ...state,
            user
        }));
    }

    //setNpsUser(gpsUser: UserMeDto | undefined) {
    //  gpsStore.update((state) => ({
    //    ...state,
    //    gpsUser
    //  }));
    //}

    //setPermissions(permissions: { [key: string]: RequestPermissionsType } | undefined) {
    //  gpsStore.update((state) => ({
    //    ...state,
    //    permissions
    //  }));
    //}

    setTenant(tenant: TenantKey | undefined) {
        if (tenant && !iamEnvironment.api[tenant])
            throw new Error(`There is no configuration for tenant ${tenant}`);

        iamStore.update((state) => ({
            ...state,
            tenant
        }));
    }

    cleanUser() {
        console.log("IamStore.clean")
        //leave token
        this.setUser(undefined);
        //this.setIamUser(undefined);
    }
}
